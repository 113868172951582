<template>
  <div class="deviceBorrowExtend" id="deviceBorrowExtend">
    <div class="deviceBorrowExtendContent">
      <div class="deviceBorrowExtendCell">
        <div class="title">设备名称</div>
        <div class="detailMsg">{{ deviceName }}</div>
      </div>
      <div class="deviceBorrowExtendCell">
        <div class="title">延长期限</div>
        <div class="detailMsgContent">
          <van-field v-model="dateCount" input-align="right" type="digit"/>
          <van-button type="primary" size="mini" @click="visible=true">{{ type }}</van-button>

        </div>
      </div>

      <el-button class="deviceBorrowExtendSure" type="primary" @click="submit">
        确定
      </el-button>
    </div>

    <van-action-sheet v-model="visible">
      <van-picker
          :default-index="2"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
          @change="onChange"
      />
    </van-action-sheet>


  </div>
</template>

<script>
import { Loading } from 'element-ui'
import { Dialog } from 'vant'

export default {
  name: 'deviceBorrowExtend',
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  computed: {
    deviceName() {
      if (this.$valueIsExist(this.detailsData, 'name') == false) {
        return ''
      }
      return this.detailsData['name']
    },
    estimatedEndTime() {
      if (this.$valueIsExist(this.detailsData, 'estimatedEndTime') == false) {
        return new Date().getTime()
      }
      return this.detailsData['estimatedEndTime']
    }
  },
  data() {
    return {
      loadingView: null,
      loading: false,
      detailsData: {},
      dateCount: 1,
      type: '日',
      visible: false,
      columns: ['年', '月', '日', '时', '分']
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getDeviceDetail()
  },
  methods: {
    submit() {
      let getTime = () => {
        let time = 0
        if (this.type == '年') {
          time = this.dateCount * 365 * 24 * 60 * 60 * 1000
        } else if (this.type == '月') {
          time = this.dateCount * 30 * 24 * 60 * 60 * 1000
        } else if (this.type == '日') {
          time = this.dateCount  * 24 * 60 * 60 * 1000
        } else if (this.type == '时') {
          time = this.dateCount  * 60 * 60 * 1000
        } else if (this.type == '分') {
          time = this.dateCount * 60 * 1000
        }
        return time + this.estimatedEndTime;
      }

      let update = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          if (this.$valueIsExist(query, 'id') == false) {
            resolve({ result: false })
            return
          }
          let id = query['orderId']
          this.$api.deviceBorrowModule
              .updateEstimatedEndTime({
                id: id,
                estimatedEndTime: getTime(),
              })
              .then((res) => {
                resolve({ result: true })
              })
              .catch((res) => {
                resolve({ result: false })
              })
        })

      }

      Dialog.confirm({
        title: '提示',
        message: '是否延长该设备借用时间'
      })
          .then(() => {
            update().then(res => {
              if (res.result == false) {
                this.$message.error('编辑失败')
                return;
              }
              localStorage.setItem('updateDeviceBorrow', new Date().getTime())
              this.postMessage({ type: 'refreshRemind' })
              this.$back()
            })
          })
          .catch(() => {
            // on cancel
          })
    },
    onConfirm(value, index) {
      this.type = value
      this.visible = false
    },
    onChange(picker, value, index) {
      this.type = value
    },
    onCancel() {
      this.visible = false
    },
    startLoading: function() {
      let dom = document.getElementById('deviceBorrowExtend')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    getDeviceDetail: function() {
      let query = this.$route.query
      if (this.$valueIsExist(query, 'id') == false) {
        return
      }
      let id = query['id']

      let getDetail = () => {
        return new Promise(resolve => {
          this.$api.deviceBorrowModule
              .shareDeviceDetail({
                deviceId: id
              })
              .then(res => {
                this.detailsData = res.data
                resolve({})
              })
              .catch(e => {
                resolve({})
              })
        })
      }

      this.loading = true
      getDetail().then(res => {
        this.loading = false
      })

    }
  }

}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceBorrowExtend {
  width: 100%;
  height: 100%;


  padding: 10px;

  .dialogContent {

  }

  .deviceBorrowExtendContent {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(100, 101, 102, 0.12);
    border-radius: 8px;
    padding: 10px;

    .deviceBorrowExtendSure {
      width: 100%;
      margin-top: 10px;
    }

    .deviceBorrowExtendCell {
      width: 100%;
      display: flex;
      justify-content: center;

      .title {
        width: 80px;
        height: 44px;
        line-height: 44px;
      }

      .detailMsg {
        width: calc(100% - 90px);
        line-height: 44px;
        text-align: right;

      }

      .detailMsgContent {
        width: calc(100% - 90px);
        line-height: 44px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

    }


  }


}

</style>